App.theme = null;
App.themes = [];

App.addThemeListener = function (callback) {
	$('body').on('app.theme-changed', callback);
};

App.registerTheme = function (set) {
	const prototype = {
		get(name) {
			return this[name];
		}
	};
	App.themes.push($.extend(prototype, App.themes[0], set));
};

App.selectTheme = function () {
	if (App.theme !== null && App.themeName === App.theme.name) {
		return;
	}
	App.theme = App.themes.find(item => item.name === App.themeName);
	$('body').trigger('app.theme-changed');
};

App.registerTheme({
	name: '',
	'sparkline-line-width': 1,
	'sparkline-blue-line-color': 'rgb(31, 119, 180)',
	'sparkline-blue-fill-color': 'rgba(31, 119, 180, .2)',
	'sparkline-green-line-color': 'rgb(0, 180, 10)',
	'sparkline-green-fill-color': 'rgba(0, 180, 10, .2)',
	'sparkline-red-line-color': 'rgb(203, 51, 0)',
	'sparkline-red-fill-color': 'rgba(203, 51, 0, .2)',
});

App.registerTheme({
	name: 'dark',
	'sparkline-line-width': 1,
	'sparkline-green-line-color': 'rgba(83, 185, 135, .8)',
	'sparkline-green-fill-color': 'rgba(83, 185, 135, .4)',
	'sparkline-red-line-color': 'rgba(235, 77, 92, .8)',
	'sparkline-red-fill-color': 'rgba(235, 77, 92, .4)',
});

App.selectTheme();
